export class GoogleMapsLoader {
  private readonly apiKey: string | null;
  private readonly options: any;
  private api: any;
  private loader?: Promise<any>;
  private resolve?: (value: any) => void;
  private reject?: (reason?: any) => void;

  static CALLBACK_NAME = '_dk_google_maps_loader_cb';

  constructor (apiKey: string | null = null, options: any = {}) {
    this.apiKey = apiKey
    this.options = options

    if (typeof window === 'undefined') {
      throw new Error('google-maps is supported only in browser environment')
    }
  }

  load (): Promise<any> {
    if (this.api) {
      return Promise.resolve(this.api)
    }
    if (this.loader) {
      return this.loader
    }

    (window as any)[GoogleMapsLoader.CALLBACK_NAME] = () => {
      this.api = (window as any).google
      if (this.resolve) this.resolve(this.api)
    }

    (window as any).gm_authFailure = () => {
      if (this.reject) this.reject(new Error('google-maps: authentication error'))
    }

    this.loader = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      const script = document.createElement('script')
      script.src = this.createUrl()
      script.async = true
      script.onerror = (e) => reject(e)
      document.head.appendChild(script)
    })

    return this.loader
  }

  createUrl (): string {
    const parameters = [
      `callback=${GoogleMapsLoader.CALLBACK_NAME}`
    ]
    if (this.apiKey) {
      parameters.push(`key=${this.apiKey}`)
    }
    for (let name in this.options) {
      if (Object.prototype.hasOwnProperty.call(this.options, name)) {
        let value = this.options[name]
        if (name === 'version') {
          name = 'v'
        }
        if (name === 'libraries') {
          value = value.join(',')
        }
        parameters.push(`${name}=${value}`)
      }
    }

    return `https://maps.googleapis.com/maps/api/js?${parameters.join('&')}`
  }
}
