import { GoogleMapsLoader } from '@/plugins/google-maps-loader'
export default class GoogleMapsController {
  private static instance: GoogleMapsController;
  private loader: any
  private isSettingMaps = false

  public static getInstance (): GoogleMapsController {
    if (!GoogleMapsController.instance) {
      GoogleMapsController.instance = new GoogleMapsController()
      window.GoogleMapsController = GoogleMapsController.instance
    }

    return GoogleMapsController.instance
  }

  public async getGoogleMaps () {
    if (!this.loader && !this.isSettingMaps) {
      this.isSettingMaps = true
      try {
        const loader = new GoogleMapsLoader(process.env.VUE_APP_GOOGLE_MAP_API_KEY)
        this.loader = await loader.load()
        this.isSettingMaps = false
      } catch (error) {
        // Silent
      }
    }

    return this.loader
  }
}
